

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import Header from './Header';
import { APIAreasGet, APIAreasPost, AUTH_TOKEN } from '../Utilities/APIS';
const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

export default class Areas extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        show:false,
        showAlert:false,
        postMsg:'',
        ResearchAreasData:[],
        ResearchArea:'',
        ResearchDescriptionInputs:[{data:''}],
        
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIAreasGet,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchAreasData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}
handleClose = () => {this.setState({show:false});}
handleShow = () => {this.setState({show:true});}

setResearchArea = (text) =>{this.setState({ResearchArea:text.target.value})}
setResearchSDescription = (text) =>{this.setState({ResearchDescription:text.target.value})}
setResearchDescriptionTextInputs = (InputsArray) =>{this.setState({ResearchDescriptionInputs:InputsArray})}

handleAdd = (currentInputs,inputsToSet) =>{
    // console.log("Adding..");
    const newInputs = [...currentInputs];
    newInputs.push('');

    if(inputsToSet === 'ResearchDescription'){this.setResearchDescriptionTextInputs(newInputs)}
}
handleDelete = (currentInputs,inputsToSet) =>{
    // console.log("Deleting..");
    const newInputs = [...currentInputs];
    newInputs.pop();
    if(inputsToSet === 'ResearchDescription'){this.setResearchDescriptionTextInputs(newInputs)}
}
handleTextInputChange = (text,index,currentInputs,inputsToSet) =>{
    // console.log("Handling Changes..");
    // console.log(".."+JSON.stringify(text));
    const newInputs = [...currentInputs];
    newInputs[index]={data:text.target.value};
    if(inputsToSet === 'ResearchDescription'){this.setResearchDescriptionTextInputs(newInputs)}
}


postResearchArea =  async () => 
    {
        let area = this.state.ResearchArea;
        let description = JSON.stringify(this.state.ResearchDescriptionInputs);
        console.log(area);
        console.log(description);


        try
        {
            const formData = new FormData();
            formData.append('name',area);
            formData.append('description',description);
            const postRequest = await axios.post(APIAreasPost,formData, {headers}
        )
            
            let result = postRequest;
            console.log(JSON.stringify(result));
            console.log(result.data);
            this.setState({postMsg:result.data});
            this.setState({showAlert: true});
            this.loadData();
            this.handleClose();
        }
        catch (error)
            {
                alert("An Error", error+"\n\nHost Not Found \n Check Your Network Connections\n\n")
            };
    }

render() {
    const {show,showAlert,ResearchAreasData,ResearchDescriptionInputs} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text white-text' >Research Areas</strong><span className='strong-text white-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New
                                    </Button>
                                </ButtonGroup>
                                </div>
                        </div><br/>
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Name</th>
                                            {/* <th className="tr-td-labels text-center">Description</th> */}
                                            {/* <th className="tr-td-labels text-center">Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ResearchAreasData && ResearchAreasData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{item.id}</td>
                                            <td className="tr-td-labels text-center">{item.name}</td>
                                            {/* <td className="tr-td-labels text-center">{item.description}</td> */}
                                            {/* <td className="tr-td-labels text-center"><i  className="three-table-dots"  data-bs-toggle="dropdown">&#8226;&#8226;&#8226;</i></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>

                                </div>
                            </div>


                            <Modal show={show} onHide={()=>this.handleClose()}>
                                <Modal.Header>
                                <Modal.Title>Adding An New Research Area</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <div>
                                            <p className="primary-text" >Name</p>
                                            <input type="text" onChange={text=>this.setResearchArea (text)}   className="form-control form-inputs" autoComplete="off" placeholder="Area Name"/>
                                        </div><br/>

                                        {/* <div>
                                            <p className="primary-text" >Description</p>
                                            <input type="text" onChange={text=>this.setResearchSDescription (text)}    className="form-control form-inputs" autoComplete="off" placeholder="Description"/>
                                        </div><br/> */}
                                        <label className="primary-text"><b>ResearchDescription *</b></label>
                                        {ResearchDescriptionInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.ResearchDescriptionInputs,'ResearchDescription')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.ResearchDescriptionInputs,'ResearchDescription')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.ResearchDescriptionInputs,'ResearchDescription')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.postResearchArea()}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}
