
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LogIn from './Components/Auth/LogIn';
import Dashboard from './Components/Pages/Dashboard';
import Researches from './Components/Pages/Researches';
import Researchers from './Components/Pages/Researchers';
import Details from './Components/Pages/Details';
import Areas from './Components/Pages/Areas';
import Universities from './Components/Pages/Universities';
import ViewMore from './Components/Pages/ViewMore';
import Users from './Components/Pages/Users';
import Profile from './Components/Pages/Profile';
function App() {

	return (
        <Router>
			<Routes>
				<Route path="/" element={<LogIn/>} />

				<Route path="/dashboard" element={<Dashboard/>} />
				<Route path="/details" element={<Details/>} />
				<Route path="/researches" element={<Researches/>} />
				<Route path="/researchers" element={<Researchers/>} />
				<Route path="/areas" element={<Areas/>} />
				<Route path="/viewMore" element={<ViewMore/>} />
				<Route path="/users" element={<Users/>} />
				<Route path="/profile" element={<Profile/>} />
				<Route path="/universities" element={<Universities/>} />
			</Routes>
        </Router>
		
);}

export default App;
