

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import Header from './Header';
import { APIUserCreate, APIUsersList, AUTH_TOKEN } from '../Utilities/APIS';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

export default class Users extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        show:false,
        showAlert:false,
        UsersData:[],

        NewUserFullName:'',
        NewUserEmail:'',
        NewUserPassword:'',
        postMsg:'',
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIUsersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({UsersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}
handleClose = () => {this.setState({show:false});}
handleShow = () => {this.setState({show:true});}

setNewUserFullName = (text) =>{this.setState({NewUserFullName:text.target.value})}
setNewUserEmail = (text) =>{this.setState({NewUserEmail:text.target.value})}
setNewUserPassword = (text) =>{this.setState({NewUserPassword:text.target.value})}

        
postUser =  async () => 
    {
        console.log("=======> Positing ")
        let fullName = this.state.NewUserFullName;
        let email = this.state.NewUserEmail;
        let password = this.state.NewUserPassword;



        try
        {
            const postRequest = await axios.post(APIUserCreate,{
                "fullName":fullName,"email":email,"password":password},{headers}
        )
            
            let result = postRequest;
            console.log(JSON.stringify(result));
            console.log(result.data);
            this.setState({postMsg:"New User Has Been Created Very Well"});
            this.setState({showAlert: true});
            this.loadData();
            this.handleClose();
        }
        catch (error)
            {
                console.log("===========>>>>>>>>>>"+error)
                alert("An Error", error+"\n\nHost Not Found \n Check Your Network Connections\n\n")
            };
    }

render() {
    const {show,showAlert,UsersData} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Universities </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                <Button variant="primary" onClick={()=>this.handleShow()}>
                                    Add New
                                </Button>
                            </ButtonGroup>
                            </div>
                    </div><br/>
                    <div className="row">
                        {showAlert ?(<> 
                                <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                    <Alert.Heading>Success </Alert.Heading>
                                    <p>{this.state.postMsg}</p>
                                </Alert>
                            </>) :(<> </>)}
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                <table className="table table-hover"  id="table">
                                <thead>
                                    <tr>
                                        <th className="tr-td-labels text-center"> SR.No</th>
                                        <th className="tr-td-labels text-center">Name</th>
                                        <th className="tr-td-labels text-center">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UsersData && UsersData.map((item,index)=>(
                                        <tr key={index}>
                                        <td className="tr-td-labels text-center">{index+1}</td>
                                        <td className="tr-td-labels text-center">{item.fullName}</td>
                                        <td className="tr-td-labels text-center">{item.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                                </div>

                            </div>
                        </div>


                        <Modal show={show} onHide={()=>this.handleClose()}>
                            <Modal.Header>
                            <Modal.Title>Adding An New User</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <div>
                                        <p className="primary-text" >Name</p>
                                        <input type="text" onChange={text=>this.setNewUserFullName (text)}   className="form-control form-inputs" autoComplete="off" placeholder="Full Name"/>
                                    </div><br/>
                                    <div>
                                        <p className="primary-text" >Email</p>
                                        <input type="text" onChange={text=>this.setNewUserEmail (text)}   className="form-control form-inputs" autoComplete="off" placeholder="Email"/>
                                    </div><br/>
                                    <div>
                                        <p className="primary-text" >Password</p>
                                        <input type="password"  placeholder="**************" onChange={text=>this.setNewUserPassword (text)}   className="form-control form-inputs" autoComplete="off"/>
                                    </div><br/>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="danger" onClick={()=>this.handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={()=>this.postUser()}>
                                Save Changes
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    </div>
                    {/* End col */}
            </div>
);
}}
