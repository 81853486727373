
import { APP_AUTH_TOKEN, APP_URL } from "./Env";


var BaseUrl = APP_URL;
export const AUTH_TOKEN = APP_AUTH_TOKEN; 

export const APIAreasPost = BaseUrl+"/api/ria/areas/create";
export const APIAreasGet = BaseUrl+"/api/ria/areas/list/all";
export const APIUniversityGet = BaseUrl+ "/api/ria/universities/list/all";
export const APIUniversityPost = BaseUrl+ "/api/ria/universities/create";
export const APIResearchGet = BaseUrl+"/api/ria/research/list/all";
export const APIUniversitiesGet = BaseUrl+"/api/ria/universities/list/all";

export const APIResearchesGet = BaseUrl+"/api/ria/research/list/all";
export const APIResearchersList = BaseUrl+"/api/ria/researcher/list/all";
export const APIShowResearcherImage = BaseUrl+"/api/ria/researcher/profile/image/file/";

export const APIResearchersGet = BaseUrl+"/api/ria/researcher/list/all";

// User 
export const APIUserCreate = BaseUrl+"/api/ria/users/create";
export const APIUserListByEmail = BaseUrl+"/api/ria/list/user/by/email/";
export const APIUserListById = BaseUrl+"/api/ria/list/user/by/id/";
export const APIUsersList = BaseUrl+"/api/ria/users/list/all";
export const APIUserUpdate = BaseUrl+"/api/ria/user/update/";
export const APIUserUpdatePassword = BaseUrl+"/api/ria/user/password/update/";
