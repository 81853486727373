

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import NextIcon from "../Utilities/imgs/next.png"
import Header from './Header';
import { APIAreasGet, APIResearchersGet, APIResearchGet, AUTH_TOKEN } from '../Utilities/APIS';
import { getIdCategory, getIdFullName } from '../Utilities/Functions';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Researches extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchData:[],
        AreasData:[],
        FilteredData:[],
        FilteredDataByStatus:[],
        ResearchersData:[],
    }    
}

componentDidMount(){
    this.loadData();
}



loadData = () =>{
    axios.get(APIResearchGet,{headers} )
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({FilteredDataByStatus:jsonResults});
        this.setState({FilteredData:jsonResults})
        this.setState({ResearchData:jsonResults})

        })
    .catch(err=>{console.log(err);})

    axios.get(APIAreasGet ,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({AreasData:jsonResults})
        })
    .catch(err=>{console.log(err);})

    axios.get(APIResearchersGet,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

filterDataByStatus = (status) =>{
    let code = String(status)
    let filteredArray = this.state.ResearchData.filter(item => item.researchStatus === code);
    this.setState({FilteredDataByStatus:filteredArray})
}
filterDataByArea = (id) => {

    let code = String(id)
    let filteredArray = this.state.FilteredDataByStatus.filter(item => item.researchArea === code);
    this.setState({FilteredData:filteredArray})
};

goToDetailsScreen = (
    uploadDate,Topic,Name,Area,Status,pdfFile,

    researchAbstract,
    researchMainObjectives,
    researchSpecificObjectives,
    researchHypothesis,
    researchTheoreticalFrame,
    researchLiteratureReview,
    researchMethodology,
    researchResults,
    researchDiscussionOfResults,
    researchRecommendations,
    researchConclusions,
    
) =>{
    // post user post
let detailsProps = {
    researchTopic:Topic,
    researcherName:Name,
    researchArea:Area,
    researchStatus:Status,
    pdfFile:pdfFile,


    researchAbstract:JSON.parse(researchAbstract),
    researchMainObjectives:JSON.parse(researchMainObjectives),
    researchSpecificObjectives:JSON.parse(researchSpecificObjectives),
    researchHypothesis:JSON.parse(researchHypothesis),
    researchTheoreticalFrame:JSON.parse(researchTheoreticalFrame),
    researchLiteratureReview:JSON.parse(researchLiteratureReview),
    researchMethodology:JSON.parse(researchMethodology),
    researchResults:JSON.parse(researchResults),
    researchDiscussionOfResults:JSON.parse(researchDiscussionOfResults),
    researchRecommendations:JSON.parse(researchRecommendations),
    researchConclusions:JSON.parse(researchConclusions),
};
this.props.navigate("/details",{ state: detailsProps });
}




displayRecordsData = () =>{


    return(
            this.state.FilteredData.map((item,index)=>(
            <div key={index}>
                <div className="card research-display-card">
                    <div className="card-header">
                        <p className="log-green-text">{getIdFullName(item.researcherName, this.state.ResearchersData)}</p>
                            <div  className="card-details-div">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p className="log-green-text details-label">Details</p>
                                        </td>
                                        <td>
                                            <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                onClick={()=>this.goToDetailsScreen(
                                                    item.uploadDate,item.researchTopic,item.researcherName,item.researchArea,item.researchStatus,
                                                    item.pdfFile,
                                                    item.researchAbstract,
                                                    item.researchMainObjectives,
                                                    item.researchSpecificObjectives,
                                                    item.researchHypothesis,
                                                    item.researchTheoreticalFrame,
                                                    item.researchLiteratureReview,
                                                    item.researchMethodology,
                                                    item.researchResults,
                                                    item.researchDiscussionOfResults,
                                                    item.researchRecommendations,
                                                    item.researchConclusions,
                                                    item.researchArea,
                                                    item.researchStatus,
                                                    
                                                )}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                    </div>
                    <div className="card-body">
                        <p className='text-black'>{item.researchTopic}</p>
                    </div>
                    <div className="card-footer">
                    <p className='text-black' >Research Status  | <b>{item.researchStatus}</b>&nbsp;&nbsp;&nbsp;&nbsp;  Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                    </div>
                </div>
            </div>
        ))
    )
}

render() {
    const {AreasData} =this.state;
return (
            <div className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Researches </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            {/* <ButtonGroup className="mb-2">
                                <Button variant="primary" onClick={()=>this.handleShow()}>
                                    Add New
                                </Button>
                            </ButtonGroup> */}
                            </div>
                    </div>

                    <div className="row">
                   {/* <div className='drop-down-researches-div'>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    Select Research Status
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item  onClick={()=>this.filterDataByStatus("All")}>
                                        <label  > View All</label>
                                    </Dropdown.Item>
                                    <Dropdown.Item  onClick={()=>this.filterDataByStatus("Completed")}>
                                        <label  > Completed</label>
                                    </Dropdown.Item>
                                    <Dropdown.Item  onClick={()=>this.filterDataByStatus("Published")}>
                                        <label  >Published</label>
                                    </Dropdown.Item>
                                    <Dropdown.Item  onClick={()=>this.filterDataByStatus("Working Progress")}>
                                        <label  > Working Progress</label>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div><br></br><br></br>*/}

                        <div className='drop-down-researches-div'>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    Select Research Area
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {AreasData && AreasData.map((item, index)=>(
                                        <Dropdown.Item key={index} onClick={()=>this.filterDataByArea(item.id)}>
                                            <label  > {item.name}</label>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div><br></br><br></br>
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                    {this.displayRecordsData()}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Researches);
