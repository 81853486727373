import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

const Base64ToPdf = ({ base64PDF, fileName }) => {
    const [pdfUrl, setPdfUrl] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
		handleDisplayPdf(); 
    },[])
    
    const base64ToBlob = (base64, mime) => {
        try {
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], { type: mime });
        } catch (e) {
            setError('The base64 string is not correctly encoded.');
            return null;
        }
    };

    const handleDisplayPdf = () => {
        const pdfBlob = base64ToBlob(base64PDF, 'application/pdf');
        if (pdfBlob) {
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);
            setError(''); // Clear any previous errors
        }
    };

    const handleDownloadPdf = () => {
        const pdfBlob = base64ToBlob(base64PDF, 'application/pdf');
        if (pdfBlob) {
            const url = URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(url);
            setError(''); // Clear any previous errors
        }
    };

    return (
        <div>
            <Button variant="primary" onClick={handleDisplayPdf} >Display PDF</Button>&nbsp;&nbsp;
            <Button variant="success" onClick={handleDownloadPdf} >Download File</Button>
            <br/>

            {error && <p style={{ color: 'red' }}>{error}</p>}
            {pdfUrl && (
                <iframe
                    src={pdfUrl}
                    width="100%"
                    height="500"
                    title="PDF Viewer"
                ></iframe>
            )}
        </div>
    );
};

export default Base64ToPdf;
